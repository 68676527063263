import { VictoryPie } from "victory";
import React from "react";
import "../App.css";

const Diagram = ({ result, width, height }) => {
  return (
    <div className="diagram-container">
      <svg width={width} height={height}>
        {/* Render the text element for the metric percentage */}
        <text
          id="diagram-text"
          x={width / 2}
          y={height / 2}
          textAnchor="middle"
          fontFamily="Trebuchet Ms, Arial, sans-serif"
          fontSize="18px"
          fontWeight="bold"
          fill="#5b5a5e"
        >
          {result} %
        </text>
        {/* Render the VictoryPie component */}
        <VictoryPie
          standalone={false} // Set standalone to false to render within SVG
          padAngle={0}
          innerRadius={Math.min(width, height) / 3.5}
          width={width}
          height={height}
          data={[
            { key: "Result", y: 100 - result },
            { key: "Remaining", y: result },
          ]}
          colorScale={["#aaaac3", "#eeeeee"]}
          animate={{ duration: 500 }} // Specify duration for the animation
          labels={() => null} // Hide labels
        />
      </svg>
    </div>
  );
};
export default Diagram;
