import React from "react";
import "../App.css";
import Diagram from "./Diagram";

const Result = ({ result, compareBoxContent }) => {
  return (
    <div className="result">
      <p id="calculator-title">RESULT</p>
      <Diagram result={result} width={300} height={300} />
      <div className="result-textbox">
        <p id="under-title">
          You have {result} % risk of sustaining an Obstetric Anal Sphincter
          Injury.
        </p>
        <div id="compare-box">{compareBoxContent}</div>
      </div>
    </div>
  );
};

export default Result;
