// App.js
import React, { useState } from "react";
import "./App.css";
import Layout from "./components/Layout";
import Description from "./components/Description";
import Calculator from "./components/Calculator";
import GCRC from "./components/GCRC";
import Navigation from "./components/Navigation";

function App() {
  const [showGcrcInfo, setShowGcrcInfo] = useState(false);

  const handleShowInfo = () => {
    setShowGcrcInfo(true);
  };

  return (
    <div className="App">
      <Layout>
        <Navigation handleShowInfo={handleShowInfo} />
        {showGcrcInfo ? (
          <GCRC />
        ) : (
          <>
            <Description />
            <Calculator />
          </>
        )}
      </Layout>
    </div>
  );
}

export default App;
