import React, { useState } from "react";
import "../App.css";
import ChooseCategory from "./ChooseCategory";
import FormCategoryA from "./FormCategoryA";
import FormCategoryB from "./FormCategoryB";
import FormCategoryC from "./FormCategoryC";

const Calculator = () => {
  const [patientCategory, setPatientCategory] = useState(null);
  const handleCategoryChange = (newCategory) => {
    setPatientCategory(newCategory);
  };

  return (
    <div id="calculator">
      {patientCategory === null ? (
        <ChooseCategory onCategoryChange={handleCategoryChange} />
      ) : patientCategory === "Category A" ? (
        <FormCategoryA />
      ) : patientCategory === "Category B" ? (
        <FormCategoryB />
      ) : patientCategory === "Category C" ? (
        <FormCategoryC />
      ) : null}
    </div>
  );
};

export default Calculator;
