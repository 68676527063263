import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

const Radiobuttons = ({
  inputName,
  groupName,
  infoContent,
  value,
  onChange,
  resetFlag,
}) => {
  const [isInfoVisible, setInfoVisibility] = useState(false);

  const handleQuestionMarkClick = () => {
    setInfoVisibility(!isInfoVisible);
  };

  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue); // Pass the selected value to the parent component
  };

  useEffect(() => {
    // Reset radio button values when resetFlag changes
    if (resetFlag) {
      // Reset the selected value to the initial value passed from the parent
      onChange(""); // Reset to empty string
    }
  }, [resetFlag, onChange]);

  return (
    <div id="radiobuttons-div">
      <label className="radiobutton-name">
        {isInfoVisible && <span id="popup-info">{infoContent}</span>}
        {inputName}{" "}
        <FontAwesomeIcon
          icon={faCircleQuestion}
          id="question-mark"
          onClick={handleQuestionMarkClick}
        />{" "}
      </label>
      <div className="radio-pair">
        <input
          type="radio"
          name={groupName}
          value="1"
          className="input-radio"
          checked={value === "1"} // Check if value matches radio button value
          onChange={handleRadioChange}
        />
        <input
          type="radio"
          name={groupName}
          value="0"
          className="input-radio"
          checked={value === "0"} // Check if value matches radio button value
          onChange={handleRadioChange}
        />
      </div>
    </div>
  );
};

export default Radiobuttons;
