import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

const Slider = ({
  inputName,
  inputType,
  minValue,
  maxValue,
  infoContent,
  value,
  onChange,
}) => {
  const [sliderValue, setSliderValue] = useState(value);
  const [textInputValue, setTextInputValue] = useState(
    value !== 0 ? String(value) : ""
  ); // Default to empty string if value is 0
  const [isInfoVisible, setInfoVisibility] = useState(false);

  useEffect(() => {
    setSliderValue(value);
    setTextInputValue(value !== 0 ? String(value) : ""); // Sync with parent value
  }, [value]);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSliderValue(newValue);
    setTextInputValue(String(newValue)); // Sync text input with slider
    onChange(newValue);
  };

  const handleTextInputChange = (event) => {
    const newValue = event.target.value;

    // Update the text input value regardless of validity
    setTextInputValue(newValue);

    // Only update the slider value and parent state if the input is a valid number within the range
    const parsedValue = parseInt(newValue, 10);
    if (
      !isNaN(parsedValue) &&
      parsedValue >= minValue &&
      parsedValue <= maxValue
    ) {
      setSliderValue(parsedValue);
      onChange(parsedValue);
    }
  };

  const handleQuestionMarkClick = () => {
    setInfoVisibility(!isInfoVisible);
  };

  return (
    <div id="text-input-div">
      <label>
        {isInfoVisible ? <span id="popup-info">{infoContent}</span> : null}
        {inputName}
        <input
          type="text"
          value={textInputValue}
          onChange={handleTextInputChange}
        />
        {inputType}
        <FontAwesomeIcon
          icon={faCircleQuestion}
          id="question-mark"
          onClick={handleQuestionMarkClick}
        />
      </label>
      <input
        type="range"
        value={sliderValue}
        min={minValue}
        max={maxValue}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default Slider;
