function CalculateResultCathegoryA(
  maternalAge,
  maternalHeight,
  maternalWeight,
  infantBirthWeight,
  infantHeadCircumference,
  smokingPrePregnancy,
  recurrentUrinaryTractInfection,
  nonOecdCountryOfBirth,
  vacuumDelivery,
  occiputPosterior,
  forcepsDelivery,
  medioLateralEpisiotomy,
  spinalAnesthesia,
  shoulderDystocia,
  medianEpisiotomy,
  epiduralAnesthesia
) {
  let heightInMetres = maternalHeight / 100;
  let heightSquared = heightInMetres * heightInMetres;
  let maternalBMI = maternalWeight / heightSquared;

  var maternalAgeSplitted = maternalAge / 5;
  var maternalHeightSplitted = maternalHeight / 10;
  var maternalBmiSplitted = maternalBMI / 5;
  var infantBirthWeightSplitted = infantBirthWeight / 100;

  var formulaA = Math.pow(Math.max(maternalAgeSplitted - 22 / 5, 0), 3);
  var formulaB = Math.pow(Math.max(maternalAgeSplitted - 35 / 5, 0), 3);
  var formulaC = Math.pow(Math.max(maternalAgeSplitted - 28 / 5, 0), 3);
  var formulaD = Math.pow(Math.max(maternalAgeSplitted - 35 / 5, 0), 3);

  var Y =
    -6.3498 +
    0.6057 * maternalAgeSplitted -
    0.1711 *
      ((formulaA - formulaB) / (13 / 5) - (formulaC - formulaD) / (7 / 5)) +
    -0.2933 * maternalHeightSplitted +
    0.115 * infantBirthWeightSplitted +
    1.9712 * nonOecdCountryOfBirth +
    -0.2557 * smokingPrePregnancy +
    0.0366 * infantHeadCircumference +
    -0.1299 * recurrentUrinaryTractInfection +
    -0.0776 * maternalBmiSplitted +
    -0.058 * epiduralAnesthesia +
    0.5906 * spinalAnesthesia +
    1.4415 * medioLateralEpisiotomy +
    1.0022 * forcepsDelivery +
    0.4625 * occiputPosterior +
    0.705 * medianEpisiotomy +
    0.6021 * shoulderDystocia +
    1.0802 * vacuumDelivery +
    -0.2841 * nonOecdCountryOfBirth * maternalAgeSplitted +
    0.0431 *
      nonOecdCountryOfBirth *
      ((formulaA - formulaB) / (13 / 5) - (formulaC - formulaD) / (7 / 5)) +
    -0.0389 * infantBirthWeightSplitted * medioLateralEpisiotomy +
    -0.4692 * vacuumDelivery * medioLateralEpisiotomy;

  let result = Math.exp(Y) / (1 + Math.exp(Y));
  let resultInPercent;
  if (result * 100 < 1 && result * 100 > 0.5) {
    result = result * 100;
    resultInPercent = parseFloat(result.toFixed(1));
  } else {
    resultInPercent = Math.round(result * 100);
  }
  return resultInPercent;
}

export default CalculateResultCathegoryA;
