import React from "react";
import "../App.css";
import Adrian from "../images/GCRC-team/Adrian.jpg";
import Ida from "../images/GCRC-team/Ida.jpg";
import Ian from "../images/GCRC-team/Ian.jpg";
import Jennie from "../images/GCRC-team/Jennie.jpg";
import Jwan from "../images/GCRC-team/Jwan.jpg";
import Julia from "../images/GCRC-team/Julia.jpg";
import Maria from "../images/GCRC-team/Maria.jpg";
import Mattias from "../images/GCRC-team/Mattias.jpg";
import Sigvard from "../images/GCRC-team/Sigvard.jpg";

const GCRC = () => {
  return (
    <div className="information-container">
      <div className="small-container">
        <h2 id="big-title">About GCRC</h2>
        <p className="information-text">
          Gothenburg Continence Research Centre (GCRC) is an international,
          multidisciplinary, internationally recognised center of excellence in
          continence care based on a triple helix cooperation between Academia,
          Clinical Practice and Industry. Our mission is to create scientific
          evidence regarding cost effective, pragmatic excellent continence care
          to enhance quality of life and preserve dignity of sufferers and
          create novel, pragmatic prevention strategies.
        </p>
      </div>
      <div className="information-content">
        <div className="images-container">
          <div className="image-row">
            <div className="image-and-name-container">
              <img
                className="gcrc-people"
                src={Sigvard}
                alt="Sigvard Åkervall"
              />
              <p className="names">
                Sigvard Åkervall, MD, PhD, Colorectal Surgeon
              </p>
            </div>
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Jwan} alt="Jwan Al-Mukhtar" />
              <p className="names">
                Jwan Al-Mukhtar, PhD, specialist in Obstetrics and Gynecology
              </p>
            </div>
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Ian} alt="Ian Milsom" />
              <p className="names">
                Professor Ian Milsom, specialist in Obstetrics and Gynecology
              </p>
            </div>
          </div>
          <div className="image-row">
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Maria} alt="Maria Gyhagen" />
              <p className="names">
                Associate professor Maria Gyhagen, specialist in Obstetrics and
                Gynecology
              </p>
            </div>
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Mattias} alt="Mattias Molin" />
              <p className="names">Mattias Molin, Biostatistician </p>
            </div>
            <div className="image-and-name-container">
              <img
                className="gcrc-people"
                src={Jennie}
                alt="Jennie Larsudd Kåverud"
              />
              <p className="names">
                Jennie Larsudd-Kåverud, PhD-student, specialist in Obstetrics
                and Gynecology
              </p>
            </div>
          </div>
          <div className="image-row">
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Julia} alt="Julia Gyhagen" />
              <p className="names">
                Julia Gyhagen, MD, PhD-student, specialist in Geriatrics
              </p>
            </div>
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Ida} alt="Ida E K Nilsson" />
              <p className="names">
                Ida E K Nilsson, PhD, specialist in Obstetrics and Gynecology
              </p>
            </div>
            <div className="image-and-name-container">
              <img className="gcrc-people" src={Adrian} alt="Adrian Wagg" />
              <p className="names">
                Professor Adrian Wagg, specialist in Geriatric and General
                Medicine
              </p>
            </div>
          </div>
        </div>
        <p className="previous-work">
          <h2 id="smaller-title">Previous work</h2>
          <b>2023</b>
          <br />
          Larsudd-Kåverud J, Gyhagen J, Åkervall S, Molin M, Milsom I, Wagg A,
          Gyhagen M. The influence of pregnancy, parity, and mode of delivery on
          urinary incontinence and prolapse surgery-a national register study.
          Am J Obstet Gynecol. 2023 Jan;228(1):61.e1-61.e13. doi:
          10.1016/j.ajog.2022.07.035. Epub 2022 Aug 3. PMID: 35932880.
          <br />
          <br />
          Gyhagen J, Åkervall S, Kåverud J, Molin M, Milsom I, Wagg A, Gyhagen
          M. The influence of age and health status for outcomes after
          mid-urethral sling surgery-a nationwide register study. Int Urogynecol
          J. 2023 Apr;34(4):939-947. doi: 10.1007/s00192-022-05364-6. Epub 2022
          Oct 1. PMID: 36181548; PMCID: PMC10038952.
          <br />
          <br />
          Nilsson IEK, Åkervall S, Molin M, Milsom I, Gyhagen M. Severity and
          impact of accidental bowel leakage two decades after no, one, or two
          sphincter injuries. Am J Obstet Gynecol. 2023
          Apr;228(4):447.e1-447.e19. doi: 10.1016/j.ajog.2022.11.1312. Epub 2022
          Dec 10. PMID: 36513133.
          <br />
          <br />
          <b>2022</b>
          <br />
          Al-Mukhtar Othman J, Åkervall S, Nilsson IEK, Molin M, Milsom I,
          Gyhagen M. Fecal incontinence in nonpregnant nulliparous women aged 25
          to 64 years-a randomly selected national cohort prevalence study. Am J
          Obstet Gynecol. 2022 May;226(5):706.e1-706.e23. doi:
          10.1016/j.ajog.2021.11.032. Epub 2021 Nov 11. PMID: 34774822.
          <br />
          <br />
          <b>2021</b>
          <br />
          Nilsson IEK, Åkervall S, Molin M, Milsom I, Gyhagen M. Symptoms of
          fecal incontinence two decades after no, one, or two obstetrical anal
          sphincter injuries. Am J Obstet Gynecol. 2021
          Mar;224(3):276.e1-276.e23. doi: 10.1016/j.ajog.2020.08.051. Epub 2020
          Aug 21. PMID: 32835724.
          <br />
          <br />
          Gyhagen M, Ellström Engh M, Husslein H, Koelbl H, Nilsson IEK, Schulz
          J, Wagg A, Milsom I. Temporal trends in obstetric anal sphincter
          injury from the first vaginal delivery in Austria, Canada, Norway, and
          Sweden. Acta Obstet Gynecol Scand. 2021 Nov;100(11):1969-1976. doi:
          10.1111/aogs.14244. Epub 2021 Aug 25. PMID: 34435349.
          <br />
          <br />
          Othman JA, Åkervall S, Molin M, Gyhagen M. Childhood nocturnal
          enuresis-a marker for pelvic floor disorders and urinary tract
          symptoms in women? Int Urogynecol J. 2021 Feb;32(2):359-365. doi:
          10.1007/s00192-020-04345-x. Epub 2020 May 30. PMID: 32474636; PMCID:
          PMC7838072.
          <br />
          <br />
          <b>2019</b>
          <br />
          Gyhagen M, Åkervall S, Molin M, Milsom I. The effect of childbirth on
          urinary incontinence: a matched cohort study in women aged 40-64
          years. Am J Obstet Gynecol. 2019 Oct;221(4):322.e1-322.e17. doi:
          10.1016/j.ajog.2019.05.022. Epub 2019 May 21. PMID: 31121136.
          <br />
          <br />
          Gyhagen M, Al-Mukhtar Othman J, Åkervall S, Nilsson I, Milsom I. The
          symptom of vaginal bulging in nulliparous women aged 25-64 years: a
          national cohort study. Int Urogynecol J. 2019 Apr;30(4):639-647. doi:
          10.1007/s00192-018-3684-5. Epub 2018 Jun 23. PMID: 29934770; PMCID:
          PMC6451022.
          <br />
          <br />
          Åkervall S, Al-Mukhtar Othman J, Molin M, Gyhagen M. Symptomatic
          pelvic organ prolapse in middle-aged women: a national matched cohort
          study on the influence of childbirth. Am J Obstet Gynecol. 2020
          Apr;222(4):356.e1-356.e14. doi: 10.1016/j.ajog.2019.10.007. Epub 2019
          Oct 19. PMID: 31639370.
          <br />
          <br />
          <b>2018</b>
          <br />
          Milsom I, Gyhagen M. The prevalence of urinary incontinence.
          Climacteric. 2019 Jun;22(3):217-222. doi:
          10.1080/13697137.2018.1543263. Epub 2018 Dec 21. PMID: 30572737.
          <br />
          Robinson D, Åkervall S, Wagg A, Chapple C, Milsom I, Gyhagen M.
          Prevalence and predictors of overactive bladder in nonpregnant
          nulliparous women below 65 years of age. Int Urogynecol J. 2018
          Apr;29(4):531-537. doi: 10.1007/s00192-017-3435-z. Epub 2017 Aug 4.
          PMID: 28779415.
          <br />
          <br />
          Jelovsek JE, Chagin K, Gyhagen M, Hagen S, Wilson D, Kattan MW, Elders
          A, Barber MD, Areskoug B, MacArthur C, Milsom I. Predicting risk of
          pelvic floor disorders 12 and 20 years after delivery. Am J Obstet
          Gynecol. 2018 Feb;218(2):222.e1-222.e19. doi:
          10.1016/j.ajog.2017.10.014. Epub 2017 Oct 19. PMID: 29056536.
          <br />
          <br />
          <b>2016</b>
          <br />
          Al-Mukhtar Othman J, Åkervall S, Milsom I, Gyhagen M. Urinary
          incontinence in nulliparous women aged 25-64 years: a national survey.
          Am J Obstet Gynecol. 2017 Feb;216(2):149.e1-149.e11. doi:
          10.1016/j.ajog.2016.09.104. Epub 2016 Oct 6. PMID: 27720862.
          <br />
          Nilsson I, Åkervall S, Milsom I, Gyhagen M. Long-term effects of
          vacuum extraction on pelvic floor function: a cohort study in
          primipara. Int Urogynecol J. 2016 Jul;27(7):1051-6. doi:
          10.1007/s00192-015-2928-x. Epub 2015 Dec 29. PMID: 26714984.
          <br />
          <br />
          <b>2015</b>
          <br />
          Gyhagen M, Åkervall S, Milsom I. Clustering of pelvic floor disorders
          20 years after one vaginal or one cesarean birth. Int Urogynecol J.
          2015 Aug;26(8):1115-21. doi: 10.1007/s00192-015-2663-3. Epub 2015 Feb
          24. PMID: 25708677.
          <br />
          <br />
          <b>2014</b>
          <br />
          Gyhagen M, Bullarbo M, Nielsen TF, Milsom I. Faecal incontinence 20
          years after one birth: a comparison between vaginal delivery and
          caesarean section. Int Urogynecol J. 2014 Oct;25(10):1411-8. doi:
          10.1007/s00192-014-2390-1. Epub 2014 May 7. PMID: 24803215.
          <br />
          <br />
          <b>2013</b>
          <br />
          Gyhagen M, Bullarbo M, Nielsen TF, Milsom I. The prevalence of urinary
          incontinence 20 years after childbirth: a national cohort study in
          singleton primiparae after vaginal or caesarean delivery. BJOG. 2013
          Jan;120(2):144-151. doi: 10.1111/j.1471-0528.2012.03301.x. Epub 2012
          Mar 14. PMID: 22413831.
          <br />
          <br />
          Gyhagen M, Bullarbo M, Nielsen TF, Milsom I. Prevalence and risk
          factors for pelvic organ prolapse 20 years after childbirth: a
          national cohort study in singleton primiparae after vaginal or
          caesarean delivery. BJOG. 2013 Jan;120(2):152-160. doi:
          10.1111/1471-0528.12020. Epub 2012 Nov 2. PMID: 23121158.
          <br />
          <br />
          Gyhagen M, Bullarbo M, Nielsen TF, Milsom I. A comparison of the
          long-term consequences of vaginal delivery versus caesarean section on
          the prevalence, severity and bothersomeness of urinary incontinence
          subtypes: a national cohort study in primiparous women. BJOG. 2013
          Nov;120(12):1548-55. doi: 10.1111/1471-0528.12367. Epub 2013 Jun 21.
          PMID: 23786421.
          <br />
          <br />
          Gyhagen M, Milsom I. Prevalence of urinary incontinence 20 years after
          childbirth in a national cohort study in singleton primiparae after
          vaginal or caesarean delivery--authors' reply. BJOG. 2013
          Aug;120(9):1150-1. doi: 10.1111/1471-0528.12268. PMID: 23837775.
          <br />
          <br />
          Gyhagen M, Milsom I. Prevalence of urinary incontinence (UI) 20 years
          after childbirth in a national cohort study in singleton primiparae
          after vaginal or caesarean delivery--authors' reply. BJOG. 2013
          Aug;120(9):1152-3. doi: 10.1111/1471-0528.12269. PMID: 23837777.
          <br />
        </p>
      </div>
    </div>
  );
};

export default GCRC;
