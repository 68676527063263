const RadioButtonError = ({ closeRadioButtonPopup }) => {
  return (
    <div className="popup">
      <div className="popup-content" id="error-popup">
        <h2 id="popup-title">Missing values</h2>
        <p id="popup-text">
          We are not able to calculate your risk if there are missing values in
          the calculator.
        </p>
        <br />
        <button className="ok-button" onClick={closeRadioButtonPopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default RadioButtonError;
