import React, { useState, useEffect } from "react";
import "../App.css";
import Slider from "./Slider";
import Radiobuttons from "./Radiobuttons";
import CalculateResultCathegoryC from "./FormulaC";
import Result from "./Result";
import Disclaimer from "./Disclaimer";
import ValueErrorPopup from "./ValueErrorPopup";
import RadioButtonError from "./RadioButtonError";

const FormCategoryC = () => {
  const [maternalAge, setMaternalAge] = useState(0);
  const [maternalHeight, setMaternalHeight] = useState(0);
  const [infantBirthWeight, setInfantBirthWeight] = useState(0);
  const [previousInfantBirthWeight, setPreviousInfantBirthWeight] = useState(0);
  const [previousOasi, setPreviousOasi] = useState("");
  const [previousVacuumDelivery, setPreviousVacuumDelivery] = useState("");
  const [previousMedioLateralEpisiotomy, setPreviousMedioLateralEpisiotomy] =
    useState("");
  const [vacuumDelivery, setVacuumDelivery] = useState("0");
  const [occiputPosterior, setOcciputPosterior] = useState("0");
  const [forcepsDelivery, setForcepsDelivery] = useState("0");
  const [shoulderDystocia, setShoulderDystocia] = useState("0");

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [result, setResult] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showRadioButtonsError, setShowRadioButtonsError] = useState(false);

  const validateInputs = (
    maternalAge,
    maternalHeight,
    infantBirthWeight,
    previousInfantBirthWeight
  ) => {
    if (
      maternalAge <= 50 &&
      maternalAge >= 14 &&
      maternalHeight <= 205 &&
      maternalHeight >= 100 &&
      infantBirthWeight >= 1000 &&
      infantBirthWeight <= 6500 &&
      previousInfantBirthWeight >= 1000 &&
      previousInfantBirthWeight <= 6500
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = validateInputs(
    maternalAge,
    maternalHeight,
    infantBirthWeight,
    previousInfantBirthWeight
  );

  const validateRadioButtons = (
    previousOasi,
    previousVacuumDelivery,
    previousMedioLateralEpisiotomy,
    vacuumDelivery,
    occiputPosterior,
    forcepsDelivery,
    shoulderDystocia
  ) => {
    return (
      previousOasi !== "" &&
      previousVacuumDelivery !== "" &&
      previousMedioLateralEpisiotomy !== "" &&
      vacuumDelivery !== "" &&
      occiputPosterior !== "" &&
      forcepsDelivery !== "" &&
      shoulderDystocia !== ""
    );
  };

  const radioButtonsIsValid = validateRadioButtons(
    previousOasi,
    previousVacuumDelivery,
    previousMedioLateralEpisiotomy,
    vacuumDelivery,
    occiputPosterior,
    forcepsDelivery,
    shoulderDystocia
  );

  const showRadioButtonsPopup = () => {
    setShowRadioButtonsError(true);
  };

  const closeRadioButtonsPopup = () => {
    setShowRadioButtonsError(false);
  };

  const closeDisclaimer = () => {
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  const showErrorPopup = () => {
    setShowError(true);
  };

  const closeErrorPopup = () => {
    setShowError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid) {
      showErrorPopup();
    } else if (!radioButtonsIsValid) {
      showRadioButtonsPopup();
    } else {
      if (disclaimerAccepted === false) {
        setShowDisclaimer(true);
      }
      let result = CalculateResultCathegoryC(
        maternalAge,
        maternalHeight,
        infantBirthWeight,
        previousInfantBirthWeight,
        previousOasi,
        previousVacuumDelivery,
        previousMedioLateralEpisiotomy,
        vacuumDelivery,
        occiputPosterior,
        forcepsDelivery,
        shoulderDystocia
      );
      setResult(result);
    }
  };

  useEffect(() => {
    if (result !== null) {
      // Scroll to the bottom of the page gradually over 500 milliseconds
      scrollToBottomSmoothly(500);
    }
  }, [result]);

  const scrollToBottomSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = document.body.scrollHeight;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition + distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setMaternalAge(0);
    setMaternalHeight(0);
    setInfantBirthWeight(0);
    setPreviousInfantBirthWeight(0);
    setPreviousOasi("");
    setPreviousVacuumDelivery("");
    setPreviousMedioLateralEpisiotomy("");
    setVacuumDelivery("0");
    setForcepsDelivery("0");
    setOcciputPosterior("0");
    setShoulderDystocia("0");
    scrollToTopSmoothly(500);
    setTimeout(() => {
      setResult(null); // Set result to null after a delay
    }, 600); // Delay setResult(null) by 600 milliseconds (adjust as needed)
  };
  const scrollToTopSmoothly = (duration) => {
    const startPosition = window.scrollY;
    const targetPosition = 0;
    const distance = startPosition - targetPosition;
    const startTime = performance.now();

    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    const scrollAnimation = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const scrollProgress = easeInOutQuad(Math.min(elapsedTime / duration, 1));
      const newPosition = startPosition - distance * scrollProgress;
      window.scrollTo(0, newPosition);
      if (elapsedTime < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };

    requestAnimationFrame(scrollAnimation);
  };

  return (
    <div className="form">
      <p id="calculator-title">CALCULATOR</p>
      <p id="under-title">
        Giving birth for the second time, where first baby was delivered
        vaginally
      </p>
      <Slider
        inputName="Maternal age: "
        inputType=" years"
        minValue="14"
        maxValue="50"
        infoContent="Your age at the delivery"
        value={maternalAge}
        onChange={setMaternalAge}
      />
      <Slider
        inputName="Maternal height: "
        inputType=" cm"
        minValue="100"
        maxValue="205"
        infoContent="Your height in centimeters"
        value={maternalHeight}
        onChange={setMaternalHeight}
      />
      <Slider
        inputName="Infant birth weight: "
        inputType=" g"
        minValue="1000"
        maxValue="6500"
        infoContent="Baby's birth weight in grams, estimated by ultrasound"
        value={infantBirthWeight}
        onChange={setInfantBirthWeight}
      />
      <Slider
        inputName="Previous infant birth weight: "
        inputType=" g"
        minValue="1000"
        maxValue="6500"
        infoContent="Baby's weight in grams at previous birth"
        value={previousInfantBirthWeight}
        onChange={setPreviousInfantBirthWeight}
      />
      <div className="radiobutton-container">
        <p id="yes-no">YES NO</p>
        <Radiobuttons
          inputName={
            <span>
              <u>Previous</u> OASI
            </span>
          }
          infoContent="A third or fourth degree tear (i.e. a vaginal tear at delivery that extends to the muscles that controls the anus), at your previous delivery"
          groupName={"previous-oasi"}
          onChange={setPreviousOasi}
          value={previousOasi}
        />
        <Radiobuttons
          inputName={
            <span>
              <u>Previous</u> vacuum delivery
            </span>
          }
          infoContent="The use of a vacuum extractor during your previous delivery"
          groupName={"previous-vacuum-delivery"}
          onChange={setPreviousVacuumDelivery}
          value={previousVacuumDelivery}
        />
        <Radiobuttons
          inputName={
            <span>
              <u>Previous</u> medio-lateral episiotomy
            </span>
          }
          infoContent="An incision made slightly to one or the other side of your perineum (the tissue between the vaginal opening and the anus), during your previous delivery"
          groupName={"previous-medio-lateral-episiotomy"}
          onChange={setPreviousMedioLateralEpisiotomy}
          value={previousMedioLateralEpisiotomy}
        />
        <span className="variable-information">
          The variables below are events that could occur during delivery.
          Please choose an option to see how your risk changes.
        </span>
        <Radiobuttons
          inputName="Vacuum delivery"
          infoContent="The use of a vacuum extractor during birth"
          groupName={"vacuum-delivery"}
          onChange={setVacuumDelivery}
          value={vacuumDelivery}
        />
        <Radiobuttons
          inputName="Forceps delivery"
          infoContent="Baby delivered by forceps"
          groupName={"forceps-delivery"}
          onChange={setForcepsDelivery}
          value={forcepsDelivery}
        />
        <Radiobuttons
          inputName="Occiput-posterior position"
          infoContent="Baby facing the mother's front"
          groupName={"occiput-posterior-position"}
          onChange={setOcciputPosterior}
          value={occiputPosterior}
        />
        <Radiobuttons
          inputName="Shoulder dystocia"
          infoContent="One or both of your baby's shoulders get stuck inside your pelvis during birth"
          groupName={"shoulder-dystocia"}
          onChange={setShoulderDystocia}
          value={shoulderDystocia}
        />
      </div>
      <div className="form-buttons-div">
        <button id="calculate-button" onClick={handleSubmit}>
          CALCULATE
        </button>
        <button id="reset-button" onClick={handleReset}>
          RESET
        </button>
      </div>
      {showError && <ValueErrorPopup closeErrorPopup={closeErrorPopup} />}
      {showRadioButtonsError && (
        <RadioButtonError closeRadioButtonPopup={closeRadioButtonsPopup} />
      )}
      {showDisclaimer && (
        <Disclaimer
          closeDisclaimer={() => {
            closeDisclaimer(); // Close disclaimer
            scrollToBottomSmoothly(500); // Scroll to bottom after closing disclaimer
          }}
        />
      )}
      {disclaimerAccepted && result && (
        <Result
          result={result}
          compareBoxContent={
            "The average risk for women giving birth to their second child where the first child was delivered vaginally is 2 %."
          }
        />
      )}
    </div>
  );
};

export default FormCategoryC;
