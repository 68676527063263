import React from "react";
import "../App.css";
import logo from "../images/logo.jpg";

const Layout = ({ children }) => {
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <div>
      {/* Header */}
      <header>
        <div id="logo-div"></div>
        <div id="title-div" onClick={reloadPage}>
          <h1 id="page-title">
            Obstetric Anal Sphincter
            <br />
            Injury Calculator
            <p id="pilot">PILOT</p>
          </h1>
        </div>
        <div id="logo-div">
          <img
            src={logo}
            id="logo"
            alt="GCRC logo
    "
          />
        </div>
      </header>

      {/* Main Content */}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
