import React from "react";
import "../App.css";

const Description = () => {
  return (
    <div id="description-div">
      <h3>
        Estimate your risk of sustaining an Obstetric Anal Sphincter Injury
        (OASI) at delivery
      </h3>
      <p id="description">
        This calculator is developed for pregnant women at term who have:
        <br /> 1. Never given birth previously
        <br />
        2. Had one previous vaginal delivery
        <br />
        3. Previously given birth one time before by cesarean section
      </p>
    </div>
  );
};

export default Description;
