import React, { useState } from "react";

const Disclaimer = ({ closeDisclaimer }) => {
  const [isChecked, setIsChecked] = useState(false); // State to track checkbox

  // Handle checkbox change event
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
  };

  return (
    <div id="disclaimer-popup" className="popup">
      <div className="disclaimer-content">
        <h2 id="disclaimer-title">Disclaimer</h2>
        <p id="disclaimer-text">
          Please be aware that the calculator cannot be 100 % accurate at
          predicting your risk of an Obstetric Anal Sphincter Injury. This
          calculator is recommended to use as a tool when planning mode of birth
          together with your obstetrician.
        </p>
        <div className="checkbox-label">
          <input
            type="checkbox"
            id="agree-checkbox"
            name="agree-checkbox"
            checked={isChecked} // Bind checkbox value to state
            onChange={handleCheckboxChange} // Update state on change
          />
          <label htmlFor="agree-checkbox">I agree</label>
        </div>
        <br />
        <button
          id="disclaimer-button"
          onClick={closeDisclaimer}
          disabled={!isChecked} // Disable the button if checkbox is not checked
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
