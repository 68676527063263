import React from "react";
import "../App.css";

const ChooseCategory = ({ onCategoryChange }) => {
  const handleButtonClick = (category) => {
    onCategoryChange(category);
  };

  return (
    <div id="buttons-div">
      <h3>CHOOSE ONE OPTION TO START CALCULATING YOUR RISK</h3>
      <button
        className="patient-category"
        onClick={() => handleButtonClick("Category A")}
      >
        I have never given birth
      </button>
      <button
        className="patient-category"
        onClick={() => handleButtonClick("Category B")}
      >
        I have given birth by cesarean one time before
      </button>
      <button
        className="patient-category"
        onClick={() => handleButtonClick("Category C")}
      >
        I have given birth vaginally one time before
      </button>
    </div>
  );
};

export default ChooseCategory;
