function CalculateResultCathegoryB(
  maternalAge,
  maternalHeight,
  maternalWeight,
  weightGain,
  infantBirthWeight,
  smokingPrePregnancy,
  vacuumDelivery,
  occiputPosterior,
  shoulderDystocia,
  medioLateralEpisiotomy,
  spinalAnesthesia
) {
  let heightInMetres = maternalHeight / 100;
  let heightSquared = heightInMetres * heightInMetres;
  let maternalBMI = maternalWeight / heightSquared;

  let previousWeight = maternalWeight - weightGain;
  let previousBMI = previousWeight / heightSquared;
  let changeInBMI = maternalBMI - previousBMI;

  var maternalAgeSplitted = maternalAge / 5;
  var maternalHeightSplitted = maternalHeight / 10;
  var maternalBmiSplitted = maternalBMI / 5;
  var infantBirthWeightSplitted = infantBirthWeight / 100;

  var formulaA = Math.pow(Math.max(maternalAgeSplitted - 25 / 5, 0), 3);
  var formulaB = Math.pow(Math.max(maternalAgeSplitted - 37 / 5, 0), 3);
  var formulaC = Math.pow(Math.max(maternalAgeSplitted - 32 / 5, 0), 3);

  var Y =
    -3.2412 +
    0 +
    0.4021 * maternalAgeSplitted +
    -0.1294 *
      ((formulaA - formulaB) / (12 / 5) - (formulaC - formulaB) / (5 / 5)) +
    -0.2615 * maternalHeightSplitted +
    -0.1467 * maternalBmiSplitted +
    -0.4083 * smokingPrePregnancy +
    -0.2329 * medioLateralEpisiotomy +
    0.7527 * vacuumDelivery +
    0.6004 * spinalAnesthesia +
    0.6202 * occiputPosterior +
    0.0529 * changeInBMI +
    0.8036 * shoulderDystocia +
    0.0963 * infantBirthWeightSplitted;
  let result = Math.exp(Y) / (1 + Math.exp(Y));
  let resultInPercent;
  if (result * 100 < 1 && result * 100 > 0.5) {
    result = result * 100;
    resultInPercent = parseFloat(result.toFixed(1));
  } else {
    resultInPercent = Math.round(result * 100);
  }
  return resultInPercent;
}

export default CalculateResultCathegoryB;
