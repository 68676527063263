const ValueErrorPopup = ({ closeErrorPopup }) => {
  return (
    <div className="popup">
      <div className="popup-content" id="error-popup">
        <h2 id="popup-title">Values missing or out of range</h2>
        <p id="popup-text">
          Please add correct values (with numbers only) to calculate your risk.
        </p>
        <br />
        <button className="ok-button" onClick={closeErrorPopup}>
          OK
        </button>
      </div>
    </div>
  );
};

export default ValueErrorPopup;
