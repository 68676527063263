// Navigation.js
import React from "react";

const Navigation = ({ handleShowInfo }) => {
  const returnToStart = () => {
    window.location.href = "/";
  };

  return (
    <nav>
      <ul className="navbar">
        <li onClick={returnToStart}>HOME</li>
        <li onClick={handleShowInfo}>ABOUT GCRC</li>
      </ul>
    </nav>
  );
};

export default Navigation;
